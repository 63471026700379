import axios from "axios";
import { useState } from "react";
import { TextField, Typography, Container, Box, Tab, Grid, Stack, Button } from "@mui/material";
import { useEffect } from "react";
import { useBuilder } from "../../../hooks/useBuilder";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Gaps } from "../../../misc/gaps";
import { Colors } from "../../../misc/colors";


const AddCustomers = () => {
    
    const {builder, refs} = useBuilder('customers.add');
    const [tabValue, setTabValue] = useState(0);
    useEffect(() => console.log(builder), [builder]);

    const [form, setForm] = useState({
        name: ""
    })

    const returnGroups = () => {
        return [...new Set(Object.keys(builder.fields).map(field => builder.fields[field].group))];
    }

    return (<Container maxWidth="xl" sx={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
        <Typography variant="h2" fontWeight="bold" textAlign="left" sx={{paddingBottom: '2rem'}}>Aggiungi committente</Typography>
        {builder && (
            builder.grouped ?
            <Box sx={{ width: '100%', typography: 'body1', marginTop: Gaps.defaultGap}}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(event, value) => setTabValue(value)}>
                        {returnGroups().map((tab, index) => (
                            <Tab key={index} label={tab} value={`tab-${index.toString()}`} sx={{
                                color: Colors.secondaryColor,
                                fontWeight: 'bold'
                            }}/>
                        ))}
                    </TabList>
                    </Box>
                    {returnGroups().map((tab, index) => (
                        <TabPanel value={`tab-${index.toString()}`} index={index} sx={{textAlign: 'left', justifyContent: 'left'}}>
                            <Grid container spacing={1}>
                                {Object.keys(builder.fields).filter(field => builder.fields[field].group === tab).map(field =>(
                                <Grid item xs={6} md={6}>
                                    {builder.fields[field].element}
                                </Grid>))}
                            </Grid>
                        </TabPanel>
                    ))}
                </TabContext>
            </Box>
            :
            <Stack spacing={3} sx={{textAlign: 'left', marginTop: Gaps.defaultGap}}>
                <Stack spacing={1}>
                    {Object.keys(builder.fields).map(field => builder.fields[field].element)}
                </Stack>
            </Stack>
        )}
        <Button onClick={() => console.log(refs)}>Invia</Button>
    </Container>);
}

export default AddCustomers;