// import ListLeads from "../views/pages/leads/ListLeads";
// import AddLead from "../views/pages/leads/AddLead";

import ListUsers from "../views/pages/users/ListUsers";

import ListCustomers from "../views/pages/customers/ListCustomers";
import AddCustomer from "../views/pages/customers/AddCustomer";
import Profile from "../views/pages/profile/Profile";
import ListLeads from "../views/pages/leads/ListLeads";
import GetLead from "../views/pages/leads/GetLead";
import CreateForm from "../views/pages/customers/CreateForm";
import ListContract from "../views/pages/contracts/ListContract";
import Home from '../views/pages/index/Index'
import ListOffers from "../views/pages/offers/ListOffers";
import ListExports from "../views/pages/exports/ListExports";
import ListSegnalazioni from "../views/pages/segnalazioni/ListSegnalazioni";
import ListTelefonia from "../views/pages/contracts/ListTelefonia";

const ChildRouter = [
    {
        path: '/',
        element: <Home />,
        title: 'Index'
    },
    {
        path: '/committenti',
        element: <ListCustomers />,
        title: 'Lista Committenti',
        group: 'Committenti'
    },
    {
        path: '/committenti/aggiungi',
        element: <AddCustomer />,
        title: 'Aggiungi Committente',
        group: 'Committenti'
    },
    {
        path: '/committenti/form/crea',
        element: <CreateForm />,
        title: 'Aggiungi Form',
        group: 'Committenti'
    },
    {
        path: '/leads',
        element: <ListLeads />,
        title: 'Lista Leads',
        group: 'Leads'
    },
    {
        path: '/leads/:id',
        element: <GetLead />,
        title: 'Dettaglio Lead',
        group: 'Leads'
    },
    {
        path: '/contratti',
        element: <ListContract />
    },
    {
        path: '/contratti/:id',
        element: <GetLead />,
        title: 'Dettaglio Lead',
        group: 'Leads'
    },
    {
        path: '/profilo',
        element: <Profile />,
        title: "Modifica profilo",
    },
    {
        path: '/utenti',
        element: <ListUsers />,
        title: "Lista utenti"
    },
    {
        path: '/offerte',
        element: <ListOffers />,
        title: "Lista Offerte"
    },
    {
        path: '/segnalazioni',
        element: <ListSegnalazioni />,
        title: "Lista Segnalazioni"
    },
    {
        path: '/telefonia',
        element: <ListTelefonia />,
        title: "Lista Contratti Telefonia"
    },
    {
        path: '/telefonia/:id',
        element: <GetLead />,
        title: 'Dettaglio Lead',
        group: 'Leads'
    },
    {
        path: '/esportazioni',
        element: <ListExports />,
        title: "Lista Esportazioni"
    }
];

export { ChildRouter };