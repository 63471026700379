import { AuthHelper } from "./AuthHelper";

const filters = {
    getPrivacyAccepted: (committente, skipAllSteps = false, role = 'AGENTE') => {
        console.log(sessionStorage.getItem('privacies'))
        const privacies = sessionStorage.getItem('privacies') && sessionStorage.getItem('privacies') !== "undefined" ? JSON.parse(sessionStorage.getItem('privacies')) : [];

        //CHECK PRIVACY SU FIDASI
        if(Object.keys(privacies).filter(x => x === 'Fidasi').length > 0 && !skipAllSteps){
            return  filters.getPrivacyAccepted('Fidasi', true);
        }
        const committentiSenzaPrivacy = [
            "ENI AGENZIA",
            "ACEA TLS",
            "A2A",
            "UNION",
            "ENEL AGENZIA",
            "UNICA",
            "AXPO",
            "DUFERCO",
            "ENEL FIBRA AGENZIA",
            "WINDTRE",
            "FASTWEB",
            "TIM",
            "EDISON AGENZIA",
            "EDISON TLS",
            "SEI ENERGIA"
        ];

                
        let user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
        // console.log(user.Attributes[1].Value === 'ADMIN' || user.Attributes[1].Value === 'OPERATORE BO');
        let attributes = sessionStorage.getItem('attributes') ? JSON.parse(sessionStorage.getItem('attributes')) : null;
        if(attributes){
            if(attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value === 'OPERATORE BO')return true;
        }
        if((user?.Attributes.filter(x => x.Name === 'custom:roles')[0].Value === 'ADMIN' || user?.Attributes.filter(x => x.Name === 'custom:roles')[0] === 'OPERATORE BO') && 
        (attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value === 'ADMIN' || attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value === 'OPERATORE BO')) return true;
        //CHECK PRIVACY AGENZIA
        const committenti = attributes.UserAttributes.filter(x => x.Name === 'custom:committenti')[0]?.Value?.split(';');
        if(committenti?.includes(committente.toUpperCase().trim())){
            return false;
        }
        if(committentiSenzaPrivacy.includes(committente.toUpperCase().trim())){
            return true;
        }
      
        let keys = privacies[committente];

        
        if(keys){
            let singleObjectKey = Object.keys(keys).filter(x => x !== 'ip')
            const acceptedPrivacies = singleObjectKey.map(x => keys[x][Object.keys(keys[x])[0]]).map(x => x.accepted)
            const isAccepted = acceptedPrivacies.some(x => x === false)
           
            return !isAccepted;
        }else{
            return false;
        }
        
    }
}

export default filters;