import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { AuthHelper } from '../views/controllers/AuthHelper';
import { FormHandler } from './builder/form';
import { Button, Grid, Input, Stack, TextField } from '@mui/material';
import { Gaps } from '../misc/gaps';
import {addField, addRequest, clear, removeField} from '../views/store/formStore';
import store from '../views/store/formStore';

let reRendered = false;

const useBuilder = () => {
    
    const [fields, setFields] = useState(null);
    const [refs, setRefs] = useState([]);

    
    const importConfig = async (page) => {
        const pageConfig = import(`../views/config/views/pages/${page.replaceAll('.', '/').replaceAll('.', '_')}.config`);
        return pageConfig;
    }

    const ReturnElement = (props) => {
        const ref = useRef();
        refs.push(ref);
        const element = <FormHandler 
            {...props}
            label={props.label} 
            placeholder={`Inserisci ${props.label}`} 
            ref={ref}
        />
        
        return element 
    }
    const createForm = useCallback(async (page, config) => {
        // if(reset) store.dispatch(clear())
        let pageConfig = await importConfig(page);
        console.log('PAGINA', page);

        if(reRendered){
            const authHelper = AuthHelper.getInstance();
            let role = authHelper.getRoles()[0];

            if(!(pageConfig.default[role]?.fields))role = 'SuperAdmin';
            if(config?.configToRemove){
                const fieldsToRemove = await importConfig(config.configToRemove);
                console.log('DA RIMOUVERE', fieldsToRemove.default[role].fields);
                for(let keyR of Object.keys(fieldsToRemove.default[role].fields)){
                    console.log(keyR);
                    if(keyR === 'Digital' || keyR === 'Committente'){
                        continue;
                    }
                    store.dispatch(removeField(keyR))

                }
            }
            for(let key of Object.keys(pageConfig.default[role].fields)){
                const field = pageConfig.default[role].fields[key]
                if(pageConfig.default[role].type === 'form'){
                    pageConfig.default[role].fields[key] = {
                        ...field,
                    }
                    
                    store.dispatch(addField(JSON.stringify({...pageConfig.default[role].fields[key], label: key})))
                    store.dispatch(addRequest({url: pageConfig.default[role].url, method: pageConfig.default[role].method, isControlled: pageConfig.default[role].isControlled}))
                }
                
            }

            
        }
        reRendered = true;
        return pageConfig;

    })

    
    useEffect(() => {
        // createForm();
    }, [])

    return {builder: createForm, ReturnElement: ReturnElement, form: fields && (<Stack spacing={3} sx={{textAlign: 'left', marginTop: Gaps.defaultGap}}>
    <Stack spacing={1}>
        {Object.keys(fields).map(field => fields[field].element)}
    </Stack>
</Stack>), refs: refs};
}

export { useBuilder};