import { CognitoUserPool, AuthenticationDetails, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { ServerConfig } from '../../connectors/Config';
import { CognitoIdentityServiceProvider, config } from 'aws-sdk'
import axios from 'axios';
import {setSub, setPlatform} from '../../views/store/formStore';
import store from '../../views/store/formStore';

const confirmUserAsync = (poolId, username, identityProvider) => {
    
    return new Promise((resolve, reject) => {
        identityProvider.adminConfirmSignUp({
            UserPoolId: poolId,
            Username: username
        }, (err,data) => {
            identityProvider.adminUpdateUserAttributes({
                UserAttributes: [{
                    Name: 'email_verified',
                    Value: 'true'
                  }
                  // other user attributes like phone_number or email themselves, etc
                ],
                UserPoolId: poolId,
                Username: username
              }, function(err) {
                if (err) {
                    reject(err);
                }  else resolve(data);
              })
            if(err)reject(err);
           
        })
    })
}

const asyncAuthenticateUser = (cognitoUser, cognitoAuthenticationDetails) => {
    return new Promise((resolve, reject) => {
        cognitoUser.associateSoftwareToken({
            onFailure : (error)=>{
                console.error(error);
            },
            associateSecretCode:(code)=>{
                console.debug(code);
            }
        });
        cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
            onSuccess: result => resolve({status: 'ok', message: result}),
            onFailure: err => reject(err),
            newPasswordRequired: err => resolve({status: 'new_password', message: 'new_password_required'})
        })
    });
}

const asyncCognitoSignup = (userPool, email, password, attributeList) => {
    return new Promise((resolve, reject) => {
        userPool.signUp(email, password, attributeList, null, (err, result) => {
            if(err)reject(err);
            else resolve(result);
        })
    })
}

const asyncChangePassword = (cognitoUser, newPassword) => {
    return new Promise((resolve, reject) => {
        cognitoUser.completeNewPasswordChallenge(
            newPassword,
            null,
            {
                onSuccess: () => resolve({status: 'ok', message: 'Password cambiata con successo'}),
                onFailure: (err) => reject({status: 'error', message: err})
            }
        )
    });
}

const listUserAsync = (poolId, identityProvider, paginationToken) => {
    // console.log('PAGINATION TOKEN', paginationToken);
    return new Promise((resolve, reject) => {
        identityProvider.listUsers({
            UserPoolId: poolId,
            Limit: 60,
            PaginationToken: paginationToken
        }, (err, data) => {
            if(err)reject(err);
            else resolve(data);
        })
    })
}

class AuthHelper {
    constructor(avoidPrivate = false){
        config.update({
            region: ServerConfig.AWS.Cognito.Region,
            accessKeyId: ServerConfig.AWS.accessKey,
            secretAccessKey: ServerConfig.AWS.secret
        });

        if(avoidPrivate){
            this.userPool = new CognitoUserPool(ServerConfig.AWS.Cognito);
            this.oldPassword = "";
        }else{
            throw new Error("This class is a Singleton. Retrieve it through the getIstance static method.");
        }
    }

    static disableUser = async(username) => {
        config.update({
            region: ServerConfig.AWS.Cognito.Region,
            accessKeyId: ServerConfig.AWS.accessKey,
            secretAccessKey: ServerConfig.AWS.secret
        });

        const identityProvider = new CognitoIdentityServiceProvider();
        const res = await identityProvider.adminDisableUser({
            Username: username,
            UserPoolId: ServerConfig.AWS.Cognito.UserPoolId
        }).promise();
        if(res.$response.error?.message)return {error: res.$response.error?.message}
        else return {message: 'Utente disabilitato con successo'}
    }

    static resetPassword = async(username, password) => {
        config.update({
            region: ServerConfig.AWS.Cognito.Region,
            accessKeyId: ServerConfig.AWS.accessKey,
            secretAccessKey: ServerConfig.AWS.secret
        });

        const identityProvider = new CognitoIdentityServiceProvider();
        const res = await identityProvider.adminSetUserPassword({
            Username: username,
            UserPoolId: ServerConfig.AWS.Cognito.UserPoolId,
            Password: password,
            Permanent: false
        }).promise();
        if(res.$response.error?.message)return {error: res.$response.error?.message}
        else return {message: 'Password resettata con successo'}
    }
    static signUp = async(email, password, firstName, lastName, vendors, vendor2, roles, company, operation) => {
        config.update({
            region: ServerConfig.AWS.Cognito.Region,
            accessKeyId: ServerConfig.AWS.accessKey,
            secretAccessKey: ServerConfig.AWS.secret
        });
        const userPool = new CognitoUserPool(ServerConfig.AWS.Cognito);
        const nameAttribute = new CognitoUserAttribute({
            Name: 'name',
            Value: firstName
        });
        const firstNameAttribute = new CognitoUserAttribute({
            Name: 'given_name',
            Value: firstName
        });
        const lastNameAttribute = new CognitoUserAttribute({
            Name: 'family_name',
            Value: lastName
        })
        const vendorAttribute = new CognitoUserAttribute({
            Name: 'custom:vendor',
            Value: vendors
        });
        const vendor2Attribute = new CognitoUserAttribute({
            Name: 'custom:vendor',
            Value: vendor2
        });
        const rolesAttribute = new CognitoUserAttribute({
            Name: 'custom:roles',
            Value: roles
        })
        const companyAttribute = new CognitoUserAttribute({
            Name: 'custom:company',
            Value: company
        })
        try{
            const res = await asyncCognitoSignup(userPool, email, password, [
                nameAttribute,
                firstNameAttribute,
                lastNameAttribute,
                vendorAttribute,
                rolesAttribute,
                companyAttribute,
                vendor2Attribute
            ]);
            await confirmUserAsync(ServerConfig.AWS.Cognito.UserPoolId, res.userSub, new CognitoIdentityServiceProvider());
            await this.resetPassword(res.userSub, password);
            const createOperation = await fetch(`https://sf9z3301hf.execute-api.eu-west-1.amazonaws.com/dev/api/v1/user/op/${res.userSub}`, {
                method: 'PUT',
                body: JSON.stringify({
                    operation: operation
                })
            });
            return res;
        }catch(ex){
            throw new Error('Errore server');
        }
        
    }
    static getUserFromIdToken = async () => {
        try{
           
            const idToken = localStorage.getItem('cruddy-apiKey');
            const url = `https://cognito-idp.${ServerConfig.AWS.Cognito.Region}.amazonaws.com/`
            const res = await axios.post(url, {
                AccessToken: idToken
            }, {
                headers: {
                    "X-Amz-Target": "AWSCognitoIdentityProviderService.GetUser",
                    "Content-Type": "application/x-amz-json-1.1",
                }
            });
            console.log('USER', res);

            if(!res.data.UserAttributes.filter(x => x.Name === 'email')[0].Value.includes('@lead4life.it')){
                window.location.href = `https://sso.lead4life.it/?return-url=https://crm.lead4life.it`
            }
            
             return res.data;
        }catch(ex){
            if(ex.response.status === 400){
                // localStorage.clear();                
            }
        }
        
    }
    static getInstance = () => {
        if(!this.istance){
            this.istance = new AuthHelper(true);
        }
        return this.istance;
    }
    
    static getUserByEmail = async(email, platform) => {
        const users = await AuthHelper.getUsers();
        console.log(users);
        for(let user of users){
            if(user.Attributes.filter(x => x.Name === 'email')[0].Value === email){
                store.dispatch(setSub(user.Attributes.filter(x => x.Name === 'sub')[0].Value));
                store.dispatch(setPlatform(platform));
                return user;
            }
        }
        return false;
    }
    
    static getUsers = async() => {
        try{
            let paginationToken;
            let userRemain = true;
            let users = [];
            while(userRemain){
                const res = await listUserAsync(ServerConfig.AWS.Cognito.UserPoolId, new CognitoIdentityServiceProvider(), paginationToken);
                users = [...users, ...res.Users];
                if(res.PaginationToken){
                    paginationToken = res.PaginationToken
                }else{
                    userRemain = false;
                }
                

            }
            return users;
        }catch(ex){
            console.log(ex);
        }
    }

    static getCommittenti = async() => {
        const user = await AuthHelper.getUserFromIdToken();
        return user.UserAttributes.filter(x => x.Name === 'custom:committenti')[0].Value.split(';');
    }
    getRoles = async () => {
        const user = await AuthHelper.getUserFromIdToken();
        return user.UserAttributes.filter(x => x.Name === 'custom:roles').Value;
    }
    login = async(credentials) => {
        try{
            const authenticationDetails = new AuthenticationDetails(credentials);
            const userData = {
                Username: credentials.Username,
                Pool: this.userPool
            }
            const cognitoUser = new CognitoUser(userData);
            const response = await asyncAuthenticateUser(cognitoUser, authenticationDetails);
            if(response.message.accessToken){
                localStorage.setItem('cruddy-apiKey', response.message.accessToken.jwtToken);
                localStorage.setItem('cruddy-user', JSON.stringify(response.message));
            }
            this.user = cognitoUser;
            return response;
        }catch(ex){
            return {status: 'error', response: ex};
        }
    }

    logout = () => {
        localStorage.clear();
        window.location.href = `https://sso.lead4life.it/?return-url=https://crm.lead4life.it`
        return true;
    }
    changePassword = async(newPassword) => {
        try{
            const res = await asyncChangePassword(this.user, this.oldPassword, newPassword);
            return {status: 'success', response: res};
        }catch(ex){
            return {status: 'error', response: ex};
        }
    }
}

export { AuthHelper };