import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import axios from "axios";
import { useState } from "react";
import DataTable from "../../components/Table";
import { Autocomplete, Button, Checkbox, Container, MenuItem, MenuList, Modal, TextField, Tooltip, Typography } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSnackBar } from "../../providers/consumers/useSnackBar";
import { AuthHelper } from "../../controllers/AuthHelper";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSelector } from "react-redux";
import GzipHelper from "../../controllers/GzipHelper";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/it";

import { DynamoDB } from 'aws-sdk';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

const ListLeads = () => {
    const sub = useSelector(state => state.subSelected);

    const [users, setUsers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const [selectedLead, setSelectedLead] = useState(null);

    const {builder, form, refs} = useBuilder();
    const [lists, setLists] = useState([]);

    const [open, setOpen] = useState(false);

    const [assignModal, setAssignModal] = useState(false);
    const [role, setRole] = useState("");

    const navigate = useNavigate();
    const { addAlert } = useSnackBar();

    const [userAttributes, setUserAttributes] = useState([]);

    const [matricola, setMatricola] = useState(null);
    const [loadingButton, setLoadingButton] = useState(false);
    const [selectedLeads, setSelectedLeads] = useState([]);
    const [committenti, setCommittenti] = useState([]);
    const [dateFilter, setDateFilter] = useState({
      from: moment().subtract(1, 'days'),
      to: moment()
    })

    const comparator = (v1, v2) => moment(v1, 'DD/MM/YYYY HH:mm:SS').unix() - moment(v2, 'DD/MM/YYYY HH:mm:SS').unix();

    const deleteLead = async(id) => {
      setLoading(true);
      try{
        const resDelete = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/lead/${id}`, {
          method: 'DELETE'
        });
        const res = await getCustomers();
        addAlert({
          message: 'Il lead è stato cancellato',
          severity: 'success'
        });
        setLoading(false);

      }catch(ex){
        addAlert({
          message: 'Errore server',
          severity: 'error'
        });
        setLoading(false);
      }
      

    }

    const softDelete = async(id) => {
      setLoading(true);
      const attributes = await AuthHelper.getUserFromIdToken();

      try{
        const resDelete = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/lead/soft-delete/${id}`, {
          method: 'PUT',
          body: JSON.stringify({
            operatore: attributes.UserAttributes.filter(x => x.Name === 'sub')[0].Value
          })
        });
        const res = await getCustomers();
        addAlert({
          message: 'Il lead è stato cancellato soft',
          severity: 'success'
        });
        setLoading(false);

      }catch(ex){
        addAlert({
          message: 'Errore server',
          severity: 'error'
        });
        setLoading(false);
      }
    }
    const getRole = async() => {
      const attributes = await AuthHelper.getUserFromIdToken();
      setRole(attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value);
      console.log('role', attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value);

      setCommittenti(attributes.UserAttributes.filter(x => x.Name === 'custom:committenti')[0]?.Value?.split(';'))
      console.log('committenti', attributes.UserAttributes.filter(x => x.Name === 'custom:committenti'));
    }

    const sendEmailACEA = async(id, consent) => {
      try{
        const res = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/sib/send/${id}`, {
          method: 'PUT',
          body: JSON.stringify({
            committente: "ACEA",
          })
        });
        const json = await res.json();
        addAlert({
          message: 'Email per ACEA inviata',
          severity: 'success'
        });
      }catch(ex){
        addAlert({
          message: 'Errore server',
          severity: 'error'
        });
        console.error('errore', ex);
      }

    }
    const sendEmailFIDASI = async(id, consent) => {
      try{
        const res = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/sib/send/${id}`, {
          method: 'PUT',
          body: JSON.stringify({
            committente: "FIDASI",

          })
        });
        const json = await res.json();
        addAlert({
          message: 'Email per FIDASI inviata',
          severity: 'success'
        });
      }catch(ex){
        addAlert({
          message: 'Errore server',
          severity: 'error'
        });
        console.error('errore', ex);
      }
    }

    const sendEmailSORGENIA = async(id, consent) => {
      try{
        const res = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/sib/send/${id}`, {
          method: 'PUT',
          body: JSON.stringify({
            committente: "SORGENIA",
          })
        });
        const json = await res.json();
        addAlert({
          message: 'Email per SORGENIA inviata',
          severity: 'success'
        });
      }catch(ex){
        addAlert({
          message: 'Errore server',
          severity: 'error'
        });
        console.error('errore', ex);
      }
    }
    const sendPhone = async(row, consent) => {
      // if(consent){
      //   addAlert({
      //     message: 'La privacy è già stata presa. Non è possibile inviare la mail per ACEA',
      //     severity: 'error'
      //   });
      //   return;
      // }
      try{
        const res = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/twilio/send/external/eroi`, {
          method: 'POST',
          body: JSON.stringify({
            "phone": row.phone,
            "baseUrl": "https://areautente.convengo.it/external-lead",
            "id": row.id
          })
        });
        const json = await res.json();
        addAlert({
          message: 'SMS per Edison inviato',
          severity: 'success'
        });
      }catch(ex){
        addAlert({
          message: 'Errore server',
          severity: 'error'
        });
        console.error('errore', ex);
      }

    }
    //rgb(46, 63, 80)
    const fetchLists = async() => {
        const res = await fetch('https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/sib/lists');
        const unzipped = await GzipHelper.unzip(await res.json())
        setLists([...unzipped.lists]);
    }

    useEffect(() => {
        fetchLists();
        builder('leads.add');
        getRole();
    }, [])
    const columns = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
        { field: 'first_name', headerName: 'Nome', width: 200, headerClassName: 'data-grid-header' },
        { field: 'last_name', headerName: 'Cognome', width: 200, headerClassName: 'data-grid-header' },
        { field: 'email', headerName: 'E-mail', width: 200, headerClassName: 'data-grid-header' },
        { field: 'phone', headerName: 'Telefono', width: 200, headerClassName: 'data-grid-header' },
        { field: 'matricola', headerName: 'Assegnato a', width: 200, headerClassName: 'data-grid-header', renderCell: (params) => role !== 'AGENTE' ?(
          <Tooltip title={`${users?.filter(x => x.sub === params.row.assigned).length > 0 && users?.filter(x => x.sub === params.row.assigned)[0]?.name} ${users?.filter(x => x.sub === params.row.assigned).length > 0 && users?.filter(x => x.sub === params.row.assigned)[0]?.family_name}`}>
            <span>{params.row.assigned}</span>
          </Tooltip>
        ): (<span>{params.row.assigned}</span>)},
        { field: 'status', headerName: 'Stato Lead', valueGetter: (params) => params.row.privacies && isConsent(params.row).some((x) => x === true)  ? '✅ Accettato' : '🚫 Non Consensato', width: 200},
        { field: 'createdAt', headerName: 'Data creazione', width: 200, headerClassName: 'data-grid-header', sortComparator: comparator},
        { field: 'updatedAt', headerName: 'Data ultima modifica', width: 200, valueGetter: (params) => moment(params.row.updatedAt).format('DD/MM/YYYY HH:mm:ss'), headerClassName: 'data-grid-header'},
        { field: 'actions', type: 'actions', getActions: (params) => [
            <GridActionsCellItem onClick={() => {setOpen(true); setSelectedLead(params.row)}} label="Aggiungi ad una lista newsletter" showInMenu  />,
            <GridActionsCellItem disabled={params.row.privacies && isConsent(params.row).some((x) => x === true)} showInMenu onClick={() => softDelete(params.row.id)} label="Elimina"/>,
            <GridActionsCellItem disabled={(role === 'AGENZIA' || role === 'TEAM LEADER AGENZIA' || role === 'AGENTE' || role === 'BO AGENZIA') ||  (!params.row.privacies && !isConsent(params.row).some((x) => x === true))} showInMenu onClick={() => softDelete(params.row.id)} label="Eliminazione soft"/>,
            <GridActionsCellItem disabled={!committenti || !committenti?.includes('ACEA_MA')} showInMenu onClick={() => sendEmailACEA(params.row.id, isConsent(params.row).some((x) => x === true))} label="E-mail ACEA"/>,
            <GridActionsCellItem showInMenu onClick={() => sendEmailFIDASI(params.row.id, isConsent(params.row).some((x) => x === true))} label="E-mail FIDASI"/>,
            <GridActionsCellItem showInMenu onClick={() => sendEmailSORGENIA(params.row.id, isConsent(params.row).some((x) => x === true))} label="E-mail SORGENIA"/>,
        //    <GridActionsCellItem showInMenu onClick={() => sendPhone(params.row, isConsent(params.row).some((x) => x === true))} label="SMS EDISON"/> 
        ]}
    ];
      const getPrivacyAccepted = (committente, user) => {

        let keys = user.privacies[committente];
    
        let singleObjectKey = Object.keys(keys).filter(x => x !== 'ip')
    
        const acceptedPrivacies = singleObjectKey.map(x => keys[x][Object.keys(keys[x])[0]]).map(x => x.accepted)
    
        const isAccepted = acceptedPrivacies.some(x => x === false)
    
        return !isAccepted;
    
    }
    const isConsent = (user) => {
      return user.privacies ? Object.keys(user.privacies).map(x => getPrivacyAccepted(x, user)) : [];
    }
    const getCustomers = async() => {
      setLoading(true);
      setCustomers([]);
      let userRemain = true;
      const attributes = await AuthHelper.getUserFromIdToken();
      let ExclusiveStartKey;
      let data = [];
      console.log(attributes);
      const vendor1 = attributes.UserAttributes.filter(x => x.Name === 'custom:vendor')[0];
      const vendor2 = attributes.UserAttributes.filter(x => x.Name === 'custom:vendor2')[0];
      console.log(vendor1);
      console.log(vendor2);

      while(userRemain){
        let res;
        switch(attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value){
          case 'AGENTE': 
            res = await axios.get(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/lead?from=${dateFilter.from.format('YYYY-MM-DD')}&to=${dateFilter.to.format('YYYY-MM-DD')}&assigned=${sub !== "" ? sub : attributes.UserAttributes.filter(x => x.Name === 'sub')[0].Value}&role=AGENTE&ExclusiveStartKey=${ExclusiveStartKey ? btoa(JSON.stringify(ExclusiveStartKey)) : ''}`);
            break;
          case 'AGENZIA':
            res = await axios.get(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/lead?agency=${attributes.UserAttributes.filter(x => x.Name === 'custom:vendor')[0].Value}&role=AGENZIA&from=${dateFilter.from.format('YYYY-MM-DD')}&to=${dateFilter.to.format('YYYY-MM-DD')}&ExclusiveStartKey=${ExclusiveStartKey ? btoa(JSON.stringify(ExclusiveStartKey)) : ''}`)
            break;
          case 'OPERATORE BO':
            res = await axios.get(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/lead?agency=${vendor2 ? [...vendor1.Value.split(';'), ...vendor2.Value.split(';')] : [...vendor1.Value.split(';')]}&role=OPERATORE BO&from=${dateFilter.from.format('YYYY-MM-DD')}&to=${dateFilter.to.format('YYYY-MM-DD')}&ExclusiveStartKey=${ExclusiveStartKey ? btoa(JSON.stringify(ExclusiveStartKey)) : ''}`)
            break;
          case 'BO AGENZIA':
            res = await axios.get(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/lead?agency=${vendor2 ? [...vendor1.Value.split(';'), ...vendor2.Value.split(';')] : [...vendor1.Value.split(';')]}&role=BO AGENZIA&from=${dateFilter.from.format('YYYY-MM-DD')}&to=${dateFilter.to.format('YYYY-MM-DD')}&ExclusiveStartKey=${ExclusiveStartKey ? btoa(JSON.stringify(ExclusiveStartKey)) : ''}`)
            break;
          case 'TEAM LEADER AGENZIA':
            res = await axios.get(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/lead?agency=${attributes.UserAttributes.filter(x => x.Name === 'custom:vendor')[0].Value.split(';')}&role=TEAM LEADER AGENZIA&from=${dateFilter.from.format('YYYY-MM-DD')}&to=${dateFilter.to.format('YYYY-MM-DD')}&ExclusiveStartKey=${ExclusiveStartKey ? btoa(JSON.stringify(ExclusiveStartKey)) : ''}`)
            break;
          case 'ADMIN':
            res = await axios.get(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/lead?from=${dateFilter.from.format('YYYY-MM-DD')}&to=${dateFilter.to.format('YYYY-MM-DD')}&role=ADMIN&ExclusiveStartKey=${ExclusiveStartKey ? btoa(JSON.stringify(ExclusiveStartKey)) : ''}`);
          default :
            break;
        }
        let unzipped = await GzipHelper.unzip(res.data)
        // console.log(unzipped.items.map(x => DynamoDB.Converter.unmarshall(x)));
        console.log(unzipped);
        if(unzipped.ExclusiveStartKey){
          ExclusiveStartKey = unzipped.ExclusiveStartKey;
        }else{
          userRemain = false;
        }
        data = [...data, ...unzipped.items.map(x => DynamoDB.Converter.unmarshall(x))]
        // if(initial)setCustomers([...unzipped.items.map(x => DynamoDB.Converter.unmarshall(x))]);
        // await setCustomers([...customers, ...unzipped.items.map(x => DynamoDB.Converter.unmarshall(x))])
        
      }

      setLoading(false);

      setCustomers([...data]);
        
        
    }
    useEffect(() => {
        // getCustomers();
        getUsers();
    }, [])
   
    const assignLeads = async() => {
      console.log(matricola);
      setLoadingButton(true);
      try{
        const body = {
          leads: [...selectedLeads],
          assignerId: (await AuthHelper.getUserFromIdToken()).UserAttributes.filter(x => x.Name === 'sub')[0].Value.split('-')[0],
          matricola: matricola.sub,
          'custom:vendor': matricola['custom:vendor']
        };
  
        const res = await fetch('https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/leads/assign', {body: JSON.stringify(body), method: 'PUT'});
  
        setLoadingButton(false);
        setOpen(false);
        addAlert({
          message: 'Il contratto è stato modificato',
          severity: 'success'
        });
        window.location.reload();
      }catch(ex){
        setOpen(false);
        setLoadingButton(false);
        addAlert({
          message: 'Errore server. Riprova più tardi o contatta il reparto tecnico.',
          severity: 'error'
        });
      }
      

    }

    const handleClick = (data = undefined, name = null) => {
        store.dispatch(clear())
        builder('leads.add');
        if(data){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, "Crea Lead");

    }

    const addToList = async(x) => {
        const res = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/sib/add/${selectedLead.id}`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        email: selectedLead.email,
                        listName: x.name,
                        first_name: selectedLead.first_name,
                        last_name: selectedLead.last_name,
                        idList: x.id
                    })
        });
        addAlert({
            message: `Lead inserito nella lista ${x.name}`,
            severity: 'success'
        })
        setOpen(false);
        
    }

    const getUsers = async() => {
      const authIstance = AuthHelper.getInstance();
      const _users = await AuthHelper.getUsers();
      if(_users){
        const _remap = [..._users.map(x => x.Attributes.map(y => ({[y.Name]: y.Value})))];
        const _usersRemapped = [];
        for(let _remapped of _remap){
            const _single_user = {};
            for(let object of _remapped){
                _single_user[Object.keys(object)[0]] = object[Object.keys(object)[0]];
                if(Object.keys(object)[0] === 'sub'){
                    _single_user.id = object.sub;
                }
            }
            _usersRemapped.push(_single_user);
        }
        setUsers([..._usersRemapped.filter(x => (x['custom:roles'] === 'AGENTE') || (x['custom:roles'] === 'AGENZIA')).filter(x => x['email'].includes('@lead4life.it') || x['email'].includes('@oxygen.it'))]);
        console.log('UTENTI', [..._usersRemapped.filter(x => (x['custom:roles'] === 'AGENTE')).filter(x => x['email'].includes('@lead4life.it') || x['email'].includes('@oxygen.it'))]);
      }
      
  }
    return (
    
    <Container sx={{minWidth: '100vw !important', marginTop: '3rem'
      }}>
      
      <BrowserView>
        {role !== 'AGENTE' && role !== 'AGENZIA' && role !== 'TEAM LEADER AGENZIA' && <Button variant="contained" color="primary" disabled={selectedLeads.length < 1} sx={{float: 'right'}} onClick={() => setAssignModal(true)}>Assegna leads</Button>}
        <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '3rem'}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap'}}>
              <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
                  <DatePicker value={dateFilter.from !== '' ? dateFilter.from : new Date()} onChange={(e) => setDateFilter({...dateFilter, from: e})} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, mr: 2}}/>} />
              </LocalizationProvider>
              -
              <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
                  <DatePicker value={dateFilter.to !== '' ? dateFilter.to : new Date()} onChange={(e) => setDateFilter({...dateFilter, to: e})} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, ml: 2}}/>} />
              </LocalizationProvider>
          </div>
          <LoadingButton loading={loading} size="small" variant="contained" sx={{ml: 2}} onClick={() => getCustomers()}>Cerca</LoadingButton>

        </div>
      </BrowserView>
      <MobileView>
        <div style={{display: 'flex', justifyContent: 'start', alignItems: 'flex-start', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '3rem'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flexWrap: 'wrap', gap: 10}}>
                <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
                    <DatePicker value={dateFilter.from !== '' ? dateFilter.from : new Date()} onChange={(e) => setDateFilter({...dateFilter, from: e})} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150}}/>} />
                </LocalizationProvider>
                
                <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
                    <DatePicker value={dateFilter.to !== '' ? dateFilter.to : new Date()} onChange={(e) => setDateFilter({...dateFilter, to: e})} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150}}/>} />
                </LocalizationProvider>

                <LoadingButton loading={loading} size="small" variant="contained"  onClick={() => getCustomers()}>Cerca</LoadingButton>

            </div>

          </div>
      </MobileView>
      <Modal
        open={assignModal}
        onClose={() => setAssignModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Scegli un utente
          </Typography>
          <Autocomplete
              id="user-selection"
              sx={{ width: 300, mt: 2 }}
              options={users}
              autoHighlight
              getOptionLabel={(option) => `${option.name} ${option.family_name}`}
              value={matricola}
              onChange={(e, newValue) => setMatricola(newValue)}
              renderOption={(props, option) => {
                return (
                  <MenuItem {...props} key={option.username}>{props.key}</MenuItem>
                );
              }}
              renderInput={(params) => (
                  <TextField
                  {...params}
                  label="Seleziona un utente"
                  />
              )}
              />
          {/* <MenuList>
            {users.map(x => 
                <MenuItem onClick={() => addToList(x)}>{x.name}</MenuItem>    
            )}
          </MenuList> */}
          <div style={{display: 'flex', marginTop: 12, justifyContent: 'space-between'}} ><LoadingButton variant="contained" color="primary" onClick={() => assignLeads()} loading={loadingButton}>Assegna</LoadingButton><Button variant="contained" color="secondary" onClick={() => setAssignModal(false)}>Chiudi</Button></div>
        </Box>
      </Modal>
        <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Scegli una lista
          </Typography>
          <MenuList>
            {lists.map(x => 
                <MenuItem onClick={() => addToList(x)}>{x.name}</MenuItem>    
            )}
          </MenuList>
        </Box>
      </Modal>
    <Typography variant="h4" color="primary" fontWeight="bold" textAlign="left" sx={{paddingBottom: '2rem', fontWeight: 'bold'}}>Lista Leads</Typography>
    {loading && <ListSkeleton />}
    {!loading &&<>
    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
      <LoadingButton loading={loading} onClick={() => getCustomers()} variant="contained" style={{alignSelf: 'flex-start'}}><RefreshIcon /></LoadingButton>
      
    </div>
    <DataTable 
    onSelectionChange={(e) => setSelectedLeads([...e])}
    noToolbar={role === 'OPERATORE BO' || role === 'ADMIN' ? false : true}
    data={customers.map(x => ({...x, createdAt: moment(x.createdAt).format('DD/MM/YYYY HH:mm:ss')}))} columns={columns} onClick={{action: 'navigate', path: ':id'}}
    initialState={{
        sorting: {
          sortModel: [{field: 'createdAt', sort: 'desc'}]
        }
      }}
    /></>}
    <FloatingActionButtons onClick={() => handleClick()}/>
    </Container>);
}

export default ListLeads;